import AppRouter from './components/AppRouter'
import Header from './components/general/Header'
import Alert from './components/general/Alert'

const App = () => {
  return (
    <>
      <Header />
      <main className="app__container">
        <AppRouter />
      </main>
      <Alert />
    </>
  )
}

export default App
