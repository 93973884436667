import React from 'react'

// primary = blue || green || red
const Button = ({ children, className, primary = `blue`, type, onClick, disabled }) => {
  return (
    <button className={`form__btn form__btn--${primary}`} type={type} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}

export default Button
