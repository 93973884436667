import { useState } from 'react'
import { Button } from '../ui/index.js'
import { isValidPhoneNumber, showSuccessMessage, showWarnMessage } from '../../helpers/utils.js'
import { triggerCallRequest } from '../../api/api.js'
import { useLiveConversationStore, useUserStore } from '../../store/useLiveConversationStore.js'

const CallTriggerForm = () => {
  const [phoneNumber, setPhoneNumber] = useUserStore((state) => [state.phoneNumber, state.setPhoneNumber])
  const [setIsCallTriggered, cleanTranscriptions, isWebsocketConnected] = useLiveConversationStore((state) => [
    state.setIsCallTriggered,
    state.cleanTranscriptions,
    state.isWebsocketConnected,
  ])

  const [number, setNumber] = useState(phoneNumber || '')

  const triggerCall = async (e) => {
    e.preventDefault()
    try {
      if (isValidPhoneNumber(number)) {
        showSuccessMessage('Your call has been triggered!')
        setIsCallTriggered(false)
        setPhoneNumber(number)
        cleanTranscriptions()

        const response = await triggerCallRequest(number)
        if (response?.success) {
          // reloadWSConnection()
          setIsCallTriggered(true)
        }
      } else {
        showWarnMessage(
          "Your number doesn't look valid! Your number has to be between 11 and 17 characters and start with '+'!",
        )
      }
    } catch (error) {
      setIsCallTriggered(false)
    }
  }

  return (
    <div className="call-trigger__container">
      <input
        className="form__input"
        type="tel"
        id="phone"
        name="phone"
        placeholder="+445556667778"
        value={number}
        onChange={(e) => setNumber(e.target.value)}
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        required
        minLength="11"
        maxLength="17"
      />
      <Button primary={'blue'} onClick={triggerCall} disabled={isWebsocketConnected}>
        Trigger call now
      </Button>
    </div>
  )
}

export default CallTriggerForm
