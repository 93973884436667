import { createWithEqualityFn as create } from 'zustand/traditional'
import { devtools, persist } from 'zustand/middleware'

const PERSIST_CONFIGURATION = {
  name: 'tokenStorage',
  version: 1,
}

export const useTokenStore = create()(
  persist(
    devtools((set, get) => ({
      // token: '',
      // setToken: (token) => {
      //   set((state) => ({ token }))
      // },
      callTriggerToken: '',
      setCallTriggerToken: (token) => {
        set((state) => ({ callTriggerToken: token }))
      },
    })),
    PERSIST_CONFIGURATION,
  ),
)
