import { createWithEqualityFn as create } from 'zustand/traditional'
import { devtools, persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { formatChatMessage } from '../helpers/utils'

const PERSIST_CONFIGURATION = {
  name: 'chatStorage',
  storage: createJSONStorage(() => sessionStorage),
  version: 2,
}

export const useChatStore = create()(
  persist(
    devtools(
      immer((set, get) => ({
        messages: [],
        addMessage: ({ text, sender }) => {
          const newFormattedMessage = formatChatMessage(text, sender)

          set((state) => {
            state.messages.push(newFormattedMessage)
          })
        },
        cleanMessages: () => {
          set({ messages: [] })
        },
      })),
    ),
    PERSIST_CONFIGURATION,
  ),
)
