import { createWithEqualityFn as create } from 'zustand/traditional'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { formatChatMessage } from '../helpers/utils'

const PERSIST_CONFIGURATION = {
  name: 'userStore',
  version: 2,
}

export const useUserStore = create()(
  persist(
    devtools(
      immer((set, get) => ({
        phoneNumber: '',
        setPhoneNumber: (phoneNumber) => {
          set((state) => ({ phoneNumber }))
        },
      })),
    ),
    PERSIST_CONFIGURATION,
  ),
)

export const useLiveConversationStore = create()(
  devtools(
    immer((set, get) => ({
      isCallTriggered: false,
      setIsCallTriggered: (status) => set(() => ({ isCallTriggered: status })),
      isWebsocketConnected: false,
      setIsWebsocketConnected: (status) => set(() => ({ isWebsocketConnected: status })),
      liveTranscriptions: [],
      addLiveTranscription: ({ text, sender }) => {
        const newTranscription = formatChatMessage(text, sender)

        set((state) => {
          state.liveTranscriptions.push(newTranscription)
        })
        // set({ liveTranscriptions: [newTranscription].concat(liveTranscriptions) }) // another order
      },
      cleanTranscriptions: () => {
        set({ liveTranscriptions: [] })
      },
    })),
  ),
)
