import ky from 'ky'
import config from '../config/config'
import { useTokenStore } from '../store/useAuthStore'

export const $request = ky.create({
  prefixUrl: `${config.server.httpLink}/api`,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
    'ngrok-skip-browser-warning': 'true',
  },
})

const [callTriggerToken] = [useTokenStore.getState().callTriggerToken]

export const $secureRequest = $request.extend({
  headers: {
    Authorization: `Bearer ${callTriggerToken}`,
    'x-access-token': `${callTriggerToken}`,
  },
})
