import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import { ErrorBoundary } from './components/general/ErrorBoundary'

const ErrorBoundaryError = ({ error }) => {
  return (
    <div>
      <p>An unhandled error occurred:</p>{' '}
      <blockquote>
        {' '}
        <code>
          {error instanceof Error ? error.message : typeof error === 'string' ? error : JSON.stringify(error)}
        </code>
        <br />
        <pre>
          {error instanceof Error ? JSON.stringify(error?.stack, null, 2) : typeof error === 'string' ? error : null}
        </pre>
        <br />
      </blockquote>
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={ErrorBoundaryError}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
)
