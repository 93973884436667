const env = process.env

const config = {
  server: {
    httpLink: env.REACT_APP_HTTP_BACKEND_URL,
    wssLink: env.REACT_APP_WSS_BACKEND_URL,
  },
  debug: env.REACT_APP_DEBUG_MODE,
}

export default config
