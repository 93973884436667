import { Button } from '../ui'
import { useChatStore } from '../../store/useChatStore'

const ChatManager = () => {
  const [clearMessages] = useChatStore((state) => [state.clearMessages])

  return (
    <section className="fixed__container chat-manager__container">
      <div className="store__container">
        {/* <Button primary={'green'} onClick={saveChatHistory}>
          Save history
        </Button> */}
        <Button primary={'red'} onClick={clearMessages}>
          Clear messages
        </Button>
      </div>{' '}
    </section>
  )
}

export default ChatManager
