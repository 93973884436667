import { useEffect, useRef } from 'react'
import config from '../../config/config.js'
import { SenderType } from '../../helpers/constants.js'
import { useAutoScroll } from '../../helpers/hooks.js'
import { showDebugMessage, reportErrorToUser } from '../../helpers/utils.js'
import { useLiveConversationStore, useUserStore } from '../../store/useLiveConversationStore.js'

const wssLink = config.server.wssLink

const TranscriptionList = () => {
  const [liveTranscriptions, addLiveTranscription, isCallTriggered, isWebsocketConnected, setIsWebsocketConnected] =
    useLiveConversationStore((state) => [
      state.liveTranscriptions,
      state.addLiveTranscription,
      state.isCallTriggered,
      state.isWebsocketConnected,
      state.setIsWebsocketConnected,
    ])
  const [phoneNumber] = useUserStore((state) => [state.phoneNumber])
  const [messageEnd] = useAutoScroll(liveTranscriptions)

  const socket = useRef()

  // TODO: consider a case when user speaks but is outside the page, e.g. he is in first page
  // in this case there is a scroll down after returning back to the current page

  useEffect(() => {
    if (isCallTriggered) {
      if (!socket.current) {
        showDebugMessage(`[CallTriggered, ws is null] Call is triggered`)

        const encodedPhoneNumber = encodeURIComponent(phoneNumber)
        const wssConnectionLink = `${wssLink}/?phoneNumber=${encodedPhoneNumber}`
        socket.current = new WebSocket(wssConnectionLink)
        showDebugMessage(
          `[CallTriggered:${isCallTriggered}|wsStatus:${socket.current.readyState}] WS connection is on a way.`,
        )
      }
      websocketConnect()
    } else if (!isCallTriggered && socket.current) {
      showDebugMessage(`[CallTriggered:${isCallTriggered}|wsStatus:${socket.current.readyState}] Tried to close`)
      socket.current?.close()
      setIsWebsocketConnected(false)
    }

    return () => {
      socket.current?.close()
      setIsWebsocketConnected(false)
    }
  }, [isCallTriggered])

  function websocketConnect() {
    showDebugMessage(`[CallTriggered:${isCallTriggered}|wsStatus:${socket.current.readyState}] Start ws connection`)
    socket.current.onopen = () => {
      if (socket.current.readyState === WebSocket.OPEN) {
        setIsWebsocketConnected(true)
      }
    }

    socket.current.onmessage = (msg) => {
      const data = JSON.parse(msg.data)

      let sender = data.event === 'user' ? SenderType.Me : data.event === 'clare' ? SenderType.Clare : ''

      addLiveTranscription({ text: data.payload.message, sender })
    }

    socket.current.onclose = () => {
      const socketMessage = 'Socket is closed'
      setIsWebsocketConnected(false)
      showDebugMessage(socketMessage)
    }

    socket.current.onerror = (error) => {
      reportErrorToUser(
        `Something went wrong in connection between client and server:${error.message}. We're trying to make the connection. If the error persists, please repeat the call again`,
        { isCallTriggered, phoneNumber, isWebsocketConnected },
        false,
        true,
      )
      setIsWebsocketConnected(false)
      websocketConnect()
    }
  }

  if (!isCallTriggered)
    return (
      <span className="absolute-center chat-history__holder">
        Enter your phone number and click the &laquo;Trigger call now&raquo; button to&nbsp;start our conversation
      </span>
    )

  if (!isWebsocketConnected)
    return (
      <span className="absolute-center chat-history__holder">
        The app is&nbsp;connecting to&nbsp;the server. This may take a&nbsp;moment. Once the connection
        is&nbsp;established, your conversation will be&nbsp;available here.
      </span>
    )

  return (
    <ul className="chat-history chat__list" id="chatList">
      {liveTranscriptions.length <= 0 && (
        <span className="absolute-center chat-history__holder">
          Answer the incoming call, your chat will stay active here
        </span>
      )}
      {liveTranscriptions.map(({ id, text, sender }) => (
        <li key={id} className={`chat__item ${sender === SenderType.Me ? `chat__item--right` : `chat__item--left`}`}>
          <div className="chat-item__block">
            <p className="chat-item__message">{text}</p>
          </div>
        </li>
      ))}
      <div ref={messageEnd}></div>
    </ul>
  )
}

export default TranscriptionList
