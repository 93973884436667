import { useState } from 'react'
import ChatHistory from '../components/Chat/ChatHistory'
import InputMessage from '../components/Chat/InputMessage'
import ChatManager from '../components/Chat/ChatManager'

function ChatPage() {
  const [loading, setLoading] = useState(false)

  return (
    <>
      <ChatManager />
      <ChatHistory loading={loading} />
      <InputMessage setLoading={setLoading} loading={loading}/>
    </>
  )
}

export default ChatPage
