import { toast } from 'react-toastify'
import { DEFAULT_TOSTIFY_CONFIG } from './constants'
import envConfig from '../config/config'

export const formatChatMessage = (text, sender) => {
  return { text, sender, id: generateUniqueValue(sender), currentDate: getHoursAndMinutes() }
}

const generateUniqueValue = (text) => {
  return `${text}_${Date.now()}`
}

const getHoursAndMinutes = () => {
  const currentDate = new Date()
  return currentDate.getHours() + ':' + currentDate.getMinutes()
}

export const isValidPhoneNumber = (number) => {
  const phoneNumberRegex = /^\+[0-9]{10,16}$/
  return phoneNumberRegex.test(number)
}

export const showDebugMessage = (debugMessage, level = 'info') => {
  const updatedMessage = `DEBUG: ${debugMessage}`

  if (envConfig.debug) {
    if (level === 'info') toast.info(updatedMessage, DEFAULT_TOSTIFY_CONFIG)
    else if (level === 'warn') toast.warn(updatedMessage, DEFAULT_TOSTIFY_CONFIG)
    else if (level === 'error') toast.error(updatedMessage, DEFAULT_TOSTIFY_CONFIG)
    else toast.info(updatedMessage, DEFAULT_TOSTIFY_CONFIG)
  } else {
    console.debug(updatedMessage)
  }
}

export const reportErrorToUser = (errorMessage, context, isExpected = false, isWithoutComment = false) => {
  console.error(errorMessage, context)
  let errorMessageForUser = errorMessage

  if (!isWithoutComment) {
    if (isExpected) errorMessageForUser += `\nPlease try again or fill the correct data`
    else {
      errorMessageForUser += `\nIf you suspect it's by our mistake, please try again later or let us know the error message`
    }
  }

  toast.error(`${errorMessageForUser}`, DEFAULT_TOSTIFY_CONFIG)
  // TODO: report to Sentry
}

export const showWarnMessage = (warnMessage) => {
  toast.warn(warnMessage, DEFAULT_TOSTIFY_CONFIG)
}

export const showSuccessMessage = (message) => {
  toast.success(message, DEFAULT_TOSTIFY_CONFIG)
}
