import { $request, $secureRequest } from './index'
import { reportErrorToUser, showDebugMessage } from '../helpers/utils'
import { useTokenStore } from '../store/useAuthStore'

const [setCallTriggerToken] = [useTokenStore.getState().setCallTriggerToken]

const extendErrorDetails = async (error) => {
  const response = await error.response.json()
  let errorMessage = ''
  console.log(response)

  if (response) {
    console.log(`There is an error response: `, response)
    const { success, message, reason } = response || {}
    if (success) return error

    if (message) errorMessage = `Error message: ${message}`
    if (error.response.status) errorMessage += ` Status: ${error.response.status}`
    if (reason) errorMessage += ` Reason: ${reason}`

    error.message = errorMessage + ` (${error.message})`
    error.status = error.response.status
  }

  return error
}

const generateToken = async (request, options, response, number) => {
  // if token is empty or token is not valid
  if (response.status === 403 || response.status === 401) {
    showDebugMessage(`401/403 response: ${JSON.stringify(response)}`, 'warn')
    const { token } = await $request
      .post('v1/auth/generate-token', {
        json: { userIdentifier: number },
      })
      .json()

    setCallTriggerToken(token)

    request.headers.set('Authorization', `Bearer ${token}`)
    request.headers.set('x-access-token', `${token}`)

    return $request(request)
  }

  if (response.status >= 500) throw new Error('Server error')

  // if (response.type === 'cors') throw new Error('CORS Error')
}

export const fetchMessage = async (body) => {
  showDebugMessage(`[fetchMessage] requestData: ${body.input}`)
  try {
    const response = await $request.post(`v1/chat`, {
      json: body,
      hooks: { beforeError: [extendErrorDetails] },
    })
    const { result } = await response.json()
    showDebugMessage(`result: ${result}`)

    return result
  } catch (error) {
    const errorMessage = `[Error] Fetch error: ${error.message}`
    showDebugMessage(errorMessage, 'error')
    reportErrorToUser(errorMessage, { body })
  }
}

export const triggerCallRequest = async (number) => {
  try {
    const response = await $secureRequest
      .post(`v1/call/trigger/${number}`, {
        hooks: {
          afterResponse: [async (request, options, response) => generateToken(request, options, response, number)],
          beforeError: [extendErrorDetails],
        },
      })
      .json()

    return response
  } catch (error) {
    reportErrorToUser(error.message, { tag: `call trigger` }, false)
    throw new Error(error.message)
  }
}
