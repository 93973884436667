export const DEFAULT_TOSTIFY_CONFIG = {
  position: 'bottom-right',
  autoClose: 15000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
}

export const SenderType = {
  Me: 'Me',
  Clare: 'Clare',
}

export const PATHS = {
  CALL: '/transcriptions',
  CHAT: '/chat',
}
